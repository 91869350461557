import React from "react";

import Check from "@mui/icons-material/Check";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Close from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Checkbox,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { authMutations } from "../../../graphql/mutations";
import { ARM_SHARE } from "../../../graphql/mutations/admin/arm";
import { GET_COMPANIES } from "../../../graphql/queries";
import {
  ARM_SHARE_TASK,
  MISSING_SHARED_ARMS,
} from "../../../graphql/queries/admin/arm";
import { GET_CATEGORIES } from "../../../graphql/queries/admin/arm";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { CustomTextField } from "../../CustomStyles/LightTextField";
import { blueButton, yellowButton } from "../../CustomStyles/buttons";
import { CustomDialog } from "../../CustomStyles/dialog";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";

export default function ARMShare({ open, handleClose, currentUser }) {
  const [armsNotToShare, setArms] = React.useState([]);
  const { data: companies, loading: loadingCompanies } = useQuery(
    GET_COMPANIES,
    {
      fetchPolicy: "network-only",
    }
  );
  const { data: categories, loading: loadingCategory } = useQuery(
    GET_CATEGORIES,
    {
      fetchPolicy: "network-only",
    }
  );

  const [
    missingArmShare,
    { data: missingArmShareData, loading: loadingMissingArmShare },
  ] = useLazyQuery(MISSING_SHARED_ARMS, {
    fetchPolicy: "network-only",
  });
  const { updateSnackbar } = authMutations;
  const [category, setCategory] = React.useState({ name: "" });
  const [companyTo, setCompanyTo] = React.useState([]);
  const [childCompanies, setChildCompanies] = React.useState(true);
  const [taskId, setTaskId] = React.useState(null);
  const [select, setSelect] = React.useState(true);

  const [armShare, { loading: loadingShare }] = useMutation(ARM_SHARE, {
    onCompleted(res) {
      onCompletedFunc(
        "ARM share has been sent. It may take a few minutes to update."
      );
      setCategory({ name: "" });
      setCompanyTo([]);
      setChildCompanies(true);
      handleClose();
      if (res && res.armShare && res.armShare.task) {
        setTaskId(res.armShare.task);
        armShareTask({
          variables: {
            taskId: res.armShare.task,
          },
        });
      }
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  const [armShareTask] = useLazyQuery(ARM_SHARE_TASK, {
    fetchPolicy: "network-only",
    onCompleted() {
      onCompletedFunc("ARM has been shared");
      setTaskId(null);
    },
    onError(errors) {
      if (
        errors &&
        errors.message &&
        errors.message === "Task has not yet completed."
      ) {
        setTimeout(() => {
          armShareTask({
            variables: {
              taskId: taskId,
            },
          });
        }, 5000);
      } else {
        onErrorFunc(
          "Your last arm share was not successful. Please try again."
        );
      }
    },
  });

  const onSave = () => {
    const newArmsNotToShare = missingArmShareData.missingSharedArms
      .filter((item) => {
        return armsNotToShare.includes(Number(item.id)) !== true;
      })
      .map((item) => Number(item.id));

    if (companyTo.length !== 0) {
      armShare({
        variables: {
          category: Number(category.id),
          companies: companyTo.map((c) => Number(c.id)),
          exclusive: !childCompanies,
          removedArmIds: newArmsNotToShare,
        },
      });
    } else {
      onErrorFunc("Select a company to share ARM with");
    }
  };

  const onCancel = () => {
    setCategory({ name: "" });
    setCompanyTo([]);
    handleClose();
  };

  const sortedCompanies =
    companies && companies.companies
      ? [...companies.companies].sort((a, b) => (a.name > b.name ? 1 : -1))
      : [];

  const selectAllCompanies = () => {
    if (select) {
      setCompanyTo(sortedCompanies);
      setSelect(false);
    } else {
      setCompanyTo([]);
      setSelect(true);
    }
  };

  const changeCategory = (value) => {
    setCategory(value || { name: "" });
    value && missingArmShare({ variables: { categoryId: Number(value.id) } });
  };

  const removeArms = (id, remove) => {
    setArms((prev) => {
      const filtered = prev.filter((p) => {
        return Number(p) !== Number(id);
      });
      if (remove) {
        return [...filtered, Number(id)];
      } else {
        return filtered;
      }
    });
  };

  const sendSnackError = () => {
    updateSnackbar({
      open: true,
      message: "Arm cannot be shared without a company owner!",
      severity: "error",
    });
  };

  const oneArmRender = (item) => {
    const included = armsNotToShare.includes(Number(item.id));
    return (
      <>
        <Grid item xs={2} style={{ padding: "10px" }}>
          <Typography>
            {item.companyArm[0] && item.companyArm[0].company
              ? item.companyArm[0].company.name
              : "No Company"}
          </Typography>
        </Grid>

        <Grid item xs={2} style={{ padding: "10px" }}>
          <Typography>{item.a.name}</Typography>
        </Grid>

        <Grid item xs={3} style={{ padding: "10px" }}>
          <Typography>{item.r ? item.r.name : null}</Typography>
        </Grid>

        <Grid item xs={3} style={{ padding: "10px" }}>
          <Typography>{item.m ? item.m.name : null}</Typography>
        </Grid>

        <Grid item xs={2}>
          <Tooltip title="Share">
            <IconButton
              onClick={() =>
                item.companyArm[0] && item.companyArm[0].company
                  ? removeArms(item.id, true)
                  : sendSnackError()
              }
            >
              <Check
                style={{
                  color: included ? "gold" : "white",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Don't Share">
            <IconButton
              onClick={() =>
                item.companyArm[0] && item.companyArm[0].company
                  ? removeArms(item.id, false)
                  : sendSnackError()
              }
            >
              <Close
                style={{
                  color: included ? "white" : "gold",
                }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </>
    );
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <CustomDialog open={open} fullWidth maxWidth="lg" onClose={handleClose}>
      <DialogTitle style={{ ...pageTitleStyles }}>ARM SHARE.</DialogTitle>
      <DialogContent style={{ color: "white" }}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Autocomplete
              id="select-category"
              options={
                categories
                  ? [...categories.categories].sort((a, b) =>
                      a.name > b.name ? 1 : -1
                    )
                  : []
              }
              value={category}
              onChange={(event, value) => changeCategory(value)}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              loading={loadingCategory}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
              style={{ width: "75%", marginBottom: "20px" }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  variant="standard"
                  label={"Select Category:"}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disableCloseOnSelect
              id="select-company"
              options={sortedCompanies}
              value={companyTo}
              multiple
              limitTags={5}
              onChange={(event, value) => setCompanyTo(value || [])}
              getOptionLabel={(option) => option.name}
              loading={loadingCompanies}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name ? option.name : ""}
                </li>
              )}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              style={{ width: "75%", marginBottom: "20px" }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  variant="standard"
                  label={"Companies to share with:"}
                  sx={{ width: "100%" }}
                />
              )}
            />
            <Button
              style={{ ...blueButton, marginBottom: "10px" }}
              onClick={selectAllCompanies}
            >
              {select ? "Select All Companies" : "Deselect All Companies"}
            </Button>
            {missingArmShareData &&
            currentUser &&
            currentUser.currentUser &&
            currentUser.currentUser.isStaff ? (
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  borderBottom: "1px solid grey",
                }}
              >
                We found {missingArmShareData.missingSharedArms.length} ARMs
                your company does not own but you have access to. Would you like
                to share them too?
              </Typography>
            ) : null}
            {missingArmShareData &&
            armsNotToShare &&
            currentUser &&
            currentUser.currentUser &&
            currentUser.currentUser.isStaff ? (
              <Grid container style={{ minWidth: "800px" }}>
                <Grid item xs={2}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    Owner
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    Action
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    Risk
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    Mitigator
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    Share
                  </Typography>
                </Grid>

                {missingArmShareData.missingSharedArms.map((item) => {
                  return oneArmRender(item);
                })}
              </Grid>
            ) : null}
            {loadingMissingArmShare && (
              <Grid container justifyContent="center">
                <CircularProgress />
              </Grid>
            )}
            {/* <Typography>
              Should child companies and observed companies of this company see
              these ARMs?
            </Typography>
            <FormControlLabel
              control={
                <CustomSwitch
                  checked={childCompanies}
                  onChange={() => setChildCompanies((prev) => !prev)}
                  value={true}
                />
              }
              label={childCompanies ? "YES" : "NO"}
            /> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          sx={{ marginRight: "10px", ...yellowButton }}
          onClick={onSave}
          loading={loadingShare}
        >
          SHARE
        </LoadingButton>
        <Button
          variant="contained"
          style={{ marginRight: "10px" }}
          onClick={onCancel}
        >
          CANCEL
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
